html,
body {
  overflow: hidden;
  height: 100vh;
}
.fc-event {
  border: none !important;
  border-radius: 4px !important;
  padding: 6px !important;
  color: #fff !important;
  font-size: 14px !important;
  text-align: center;
}

.fc-daygrid-event {
  background: transparent !important;
}

.fc .fc-button {
  background-color: #1890ff !important;
  color: white !important;
  border: none !important;
}

.fc .fc-button:hover {
  background-color: #1073cc !important;
}

.fc .fc-button-primary {
  background-color: #1890ff !important;
  color: white !important;
}

.fc .fc-today-button {
  background-color: #1890ff !important;
  color: white !important;
  border: 1px solid #1890ff !important;
}

.fc .fc-today-button:hover {
  background-color: #1073cc !important;
  color: white !important;
}

.fc .fc-prev-button,
.fc .fc-next-button {
  background-color: #1890ff !important;
  color: white !important;
  border: none !important;
}

.fc .fc-prev-button svg,
.fc .fc-next-button svg,
.fc .fc-prev-button span,
.fc .fc-next-button span {
  fill: white !important;
  color: white !important;
}

.fc .fc-today-button span {
  color: white !important;
}

.brand-name {
  color: white !important;
  font-weight: bold !important;
  font-size: 18px !important;
  text-transform: uppercase !important;
}
.fc-scrollgrid {
  height: 100% !important;
  overflow: hidden !important;
}
.calendar-container {
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow-y: hidden;
  overflow-x: auto;
}

.fc .fc-view-harness {
  height: auto !important;
  overflow: hidden !important;
}

.fc .fc-daygrid-day.fc-day-disabled {
  visibility: hidden;
}

.fc .fc-daygrid-day-frame {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.fc .fc-daygrid-day-top {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fc .fc-daygrid-body {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}
.fc .fc-daygrid-week {
  display: flex !important;
  justify-content: space-evenly;
}
